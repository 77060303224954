import type { AxiosResponse } from 'axios'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchExperimentReferrerType } from '../../referrer/types'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import type { V4ListCategoriesResponse } from '../../__codegen__/__openapi__/search-server/category/v4/category'
import {
  CategoryServiceApi,
  Configuration,
} from '../../__codegen__/__openapi__/search-server/category/v4/category'

interface AllCategoryListV4SearchRequestParamsType {
  referrer: {
    experiment: SearchExperimentReferrerType
  }
}
let serviceCache: ServiceCategory | null = null

export const getServiceCategory = ({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) => {
  if (serviceCache) {
    return serviceCache
  }
  return (serviceCache = new ServiceCategory({
    baseUrl: SEARCH_ENDPOINT,
    user,
    app,
  }))
}
export class ServiceCategory {
  private client: CategoryServiceApi
  private fleaMarketCategoryListCache: V4ListCategoriesResponse | null = null
  private fleaMarketCategoryListPromise: Promise<
    AxiosResponse<V4ListCategoriesResponse | null>
  > | null = null

  constructor({
    baseUrl,
    user,
    app,
  }: {
    baseUrl: string
    user: UserType
    app: AppType
  }) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    }
    const axiosInstance = axios.create({
      headers,
      timeout: NETWORK_TIMEOUT,
    })
    daangnAxiosInterceptors({
      client: axiosInstance,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
        plantaeSearchOriginPlugin(),
      ],
    })

    axiosRetry(axiosInstance, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })
    this.client = new CategoryServiceApi(
      new Configuration({
        apiKey: user.authToken,
      }),
      baseUrl,
      axiosInstance
    )
  }
  /**
   * 중고거래 카테고리 리스트 조회
   */
  async getFleaMarketCategoryList({
    referrer,
  }: AllCategoryListV4SearchRequestParamsType) {
    if (this.fleaMarketCategoryListCache) {
      // 캐싱된 데이터가 있으면 캐싱된 데이터를 반환
      return this.fleaMarketCategoryListCache
    }

    if (!this.fleaMarketCategoryListPromise) {
      this.fleaMarketCategoryListPromise =
        this.client.categoryServiceListCategories(
          'CATEGORY_KIND_FLEAMARKET', //kind
          undefined, //xRequestId
          undefined, //xKarrotSessionId
          referrer.experiment.headerSegmentValue ?? '', //xSearchTestGroup
          undefined, //xSearchOrigin
          undefined //xUserAgent
        )
    }

    const [error, resp] = await to(this.fleaMarketCategoryListPromise)

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data || !resp?.data?.categories) {
      return null
    }

    this.fleaMarketCategoryListCache = resp.data
    return this.fleaMarketCategoryListCache
  }
}
