import to from 'await-to-js'
import type { AxiosInstance } from 'axios'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_ENDPOINT_LEGACY,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { DocumentAppInitialize } from './AppInitialize'
import { getResultFor } from '../common'
import type { FunnelFromIdTypes } from '../../referrer/constants'
import {
  addExperimentXSearchHeader,
  addFunnelFromXSearchHeader,
} from '../../_app/utils/addCustomHeader'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import type { SearchExperimentResponseType } from './Experiment'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import type { DocumentHotKeywords } from '../../hot-keyword/services'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
} from '../../plantae/plugins'
import { isMaintenanceError } from '../../error/utils/getMaintenanceError'
import MaintenanceError from '../../error/class/MaintenanceError'

let searchV2: ServiceSearchV2 | null = null

export function getServiceSearchV2({
  app,
  user,
}: {
  app: AppType
  user: UserType
}) {
  if (searchV2) {
    return searchV2
  }

  return (searchV2 = new ServiceSearchV2({
    baseUrl: SEARCH_ENDPOINT_LEGACY,
    app,
    user,
  }))
}

export class ServiceSearchV2 {
  private client: AxiosInstance
  private app: AppType

  constructor({
    baseUrl,
    app,
    user,
  }: {
    baseUrl: string
    app: AppType
    user: UserType
  }) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: NETWORK_TIMEOUT,
    })

    daangnAxiosInterceptors({
      client: this.client,
      plugins: [
        plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
        plantaeRequestIdPlugin(),
        plantaeKarrotSessionIdPlugin({ app }),
        plantaeUserAgentPlugin({ userAgent: app.userAgent }),
        plantaeCommonHeadersPlugin(),
        plantaeSearchOriginPlugin(),
      ],
    })

    axiosRetry(this.client, {
      retries: RETRY_COUNT,
      retryDelay: () => 0,
      retryCondition: () => true,
    })

    this.app = app
  }

  /**
   * 검색 초기 데이터 가져오기(실험정보, impression 정보)
   */
  async getAppInitialize(): Promise<DocumentAppInitialize | null> {
    const [error, resp] = await to(
      this.client.get<DocumentAppInitialize>(`/api/v2/initialize`)
    )

    if (error) {
      if (isMaintenanceError(error)) {
        const errorMessage =
          error.response?.data.status?.message ??
          '더 빠르고 안정적인\n당근을 만들기 위해\n서버를 점검하고 있어요!\n 점검시간: AM 2:30~3:30'

        throw new MaintenanceError(errorMessage)
      }

      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return resp.data
  }

  /**
   * 인기 검색어 목록
   * @param regionId
   * @param userId
   * @param referrer
   */
  async getHotKeywords({
    regionId,
    referrer,
  }: {
    regionId: number
    referrer: {
      funnelFromId: FunnelFromIdTypes
      experiment: SearchExperimentReferrerType
    }
  }) {
    const [error, resp] = await to(
      this.client.get<DocumentHotKeywords>(`/api/v2/hotkeyword`, {
        params: {
          region_id: regionId,
          size: 10,
        },
        headers: {
          ...addFunnelFromXSearchHeader({
            funnelFromId: referrer.funnelFromId,
          }),
          ...addExperimentXSearchHeader(referrer.experiment),
        },
      })
    )

    if (error) {
      captureException(error)
      return null
    }

    if (!resp?.data) {
      return null
    }

    return {
      ...resp.data,
      [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
        resp.headers
      ),
    }
  }

  /**
   * 실험 커스텀 트리거가 되었을 경우 실험 정보를 가져오기 위한 API
   */
  async getSearchExperiment(referrer: SearchExperimentReferrerType) {
    const [error, resp] = await to(
      this.client.get<SearchExperimentResponseType>(
        `/api/v2/experiments/custom/infos`,
        {
          headers: addExperimentXSearchHeader(referrer),
        }
      )
    )

    if (error) {
      captureException(error)
      return null
    }
    if (!resp?.data) {
      return null
    }

    return getResultFor(resp.data)
  }
}
