import type { ExperimentUserInfo } from '../../services/search_v2'
import type {
  ExperimentSegmentType,
  ExperimentType,
} from '../stores/ExperimentStore'

export const experimentStateMapper = (
  experiment: ExperimentUserInfo
): ExperimentType => {
  const experimentSegments: ExperimentSegmentType[] =
    experiment.experiment_segments.map((segment) => {
      return {
        segmentKey: segment.key,
        segmentName: segment.name,
        segmentType: segment.type,
      }
    })
  return {
    experimentState: {
      experimentSegments: experimentSegments,
      headerSegmentKey: experiment.header_segment_key,
      headerSegmentValue: experiment.header_segment_value,
    },
  }
}
