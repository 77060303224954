import type { PropOf } from '../_app/types/props-of'
import HotKeyword from './HotKeyword'
import { Body, Container, Header, KeywordBold, Title } from './HotKeywords.css'

export interface HotKeywordsProps {
  className?: string
  queryInfo: {
    testGroup: string
  }
  keywords: {
    label: string
    source: string
    score: number
  }[]
  onKeywordClick: PropOf<typeof HotKeyword, 'onClick'>
}

const HotKeywords = (props: HotKeywordsProps) => {
  return (
    <article className={`${Container} ${props.className ?? ''}`}>
      <header className={Header}>
        <h2 className={Title} a11y-label="인기 검색">
          인기 검색
        </h2>
      </header>
      <ol className={Body}>
        {props.keywords.map((keyword, index) => (
          <HotKeyword
            className={KeywordBold}
            key={keyword.label}
            index={index}
            testGroup={props.queryInfo.testGroup}
            queryInfo={keyword.source}
            keyword={keyword.label}
            onClick={props.onKeywordClick}
          />
        ))}
      </ol>
    </article>
  )
}

export default HotKeywords
