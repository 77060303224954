import { useCallback, useMemo } from 'react'
import { usePriceWithCurrency } from '../_app'
import { TagGroup } from '../tag-group'
import { Tag } from '../tag'
import { LazyImage } from '../lazy-load-image'
import IconBids from './assets/IconBids'
import IconChatting from './assets/IconChatting'
import IconHeart from './assets/IconHeart'
import * as css from './CardFleaMarket.css'
import { Badge } from '../badge'
import {
  FLEA_MARKET_BADGE_STYLE_TYPE,
  FLEA_MARKET_BADGE_STYLE_TYPE_TO_PROPS,
} from './constants'

interface CardFleaMarketProps {
  thumbnail: string
  fallbackThumbnail: string
  title: string
  regionName: string
  republishCount: number
  createdAt: string
  publishedAt: string
  status: string | null
  bidsCount: number
  freeShareEventEnabled: boolean
  chatRoomsCount: number
  watchesCount: number
  price: number | null
  sharingText: string | null
  isBuyingCategory?: boolean
  hyperLocalGroupName?: string | null
}

const CardFleaMarket = (props: CardFleaMarketProps) => {
  const isFreeSharing = useMemo(() => {
    return props.price === 0
  }, [props.price])

  const displayRegionName = useMemo(() => {
    if (props.hyperLocalGroupName) {
      return props.hyperLocalGroupName
    }

    return props.regionName
  }, [props.hyperLocalGroupName, props.regionName])

  // https://karrot.atlassian.net/wiki/spaces/GS/pages/1602781283#%EA%B0%80%EA%B2%A9-%EC%A0%95%EB%B3%B4
  const priceWithCurrency = usePriceWithCurrency(props.price)

  const renderPriceOrSharing = useCallback(() => {
    if (props.price === null) {
      return null
    }

    // 0원(나눔)이지만, '삽니다'일 경우 0원으로 표시 https://www.notion.so/daangn/PRD-0-27847986e82a4cc69e9dd6b86990c31d
    if (isFreeSharing && props.isBuyingCategory) {
      return <div className={css.price}>{priceWithCurrency}</div>
    }

    // 0원(나눔)이지만, 키워드가 다를 경우(xProperty)
    if (isFreeSharing && props.sharingText) {
      return <div className={css.price}>{props.sharingText}</div>
    }

    if (isFreeSharing) {
      return <div className={css.freeSharing}>나눔 🧡</div>
    }

    return <div className={css.price}>{priceWithCurrency}</div>
  }, [priceWithCurrency, props.price, props.isBuyingCategory])

  const a11yLabel = useMemo(() => {
    const status = (() => {
      switch (props.status) {
        case 'ONGOING':
          return '거래중 '
        case 'RESERVED':
          return '예약중 '
        case 'CLOSED':
          if (isFreeSharing) {
            return '나눔완료 '
          }
          return '거래완료 '
        default:
          return ''
      }
    })()
    const priceOrSharing = (() => {
      if (props.price === null) {
        return ''
      }
      if (isFreeSharing && props.sharingText) {
        return `${props.sharingText} `
      }
      if (isFreeSharing) {
        return '무료나눔 '
      } else {
        return `가격 ${priceWithCurrency} `
      }
    })()
    const freeShareEvent = (() => {
      if (props.freeShareEventEnabled && props.bidsCount > 0) {
        return `응모 ${props.bidsCount}`
      }
      return ''
    })()

    return `${props.title} ${status}${displayRegionName} ${props.createdAt} ${priceOrSharing}채팅${props.chatRoomsCount} 관심${props.watchesCount} ${freeShareEvent}`
  }, [
    props.title,
    props.status,
    props.price,
    props.sharingText,
    props.bidsCount,
    props.freeShareEventEnabled,
    priceWithCurrency,
  ])

  return (
    <div className={css.container} aria-label={a11yLabel}>
      <div className={css.imageContainer}>
        <LazyImage
          className={css.image}
          src={props.thumbnail}
          fallbackSrc={props.fallbackThumbnail}
        />
      </div>
      <div className={css.main}>
        <div className={css.title}>{props.title}</div>
        <TagGroup>
          <Tag>{displayRegionName}</Tag>
          {props.republishCount === 0 ? (
            <Tag>{props.createdAt}</Tag>
          ) : (
            <Tag>{`끌올 ${props.publishedAt}`}</Tag>
          )}
        </TagGroup>
        <div className={css.status}>
          {props.status === 'CLOSED' && (
            <Badge
              className={css.statusGap}
              shape="square"
              size="medium"
              {...FLEA_MARKET_BADGE_STYLE_TYPE_TO_PROPS[
                FLEA_MARKET_BADGE_STYLE_TYPE.CLOSED
              ]}
            >
              {isFreeSharing ? '나눔완료' : '거래완료'}
            </Badge>
          )}
          {props.status === 'RESERVED' && (
            <Badge
              className={css.statusGap}
              shape="square"
              size="medium"
              {...FLEA_MARKET_BADGE_STYLE_TYPE_TO_PROPS[
                FLEA_MARKET_BADGE_STYLE_TYPE.RESERVED
              ]}
            >
              예약중
            </Badge>
          )}
          {renderPriceOrSharing()}
        </div>
        <div className={css.captions}>
          {props.freeShareEventEnabled && props.bidsCount > 0 && (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconBids className={css.iconBids} />
              </div>
              {props.bidsCount}
            </div>
          )}
          {props.chatRoomsCount > 0 && (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconChatting className={css.iconChatting} />
              </div>
              {props.chatRoomsCount}
            </div>
          )}
          {props.watchesCount > 0 && (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconHeart className={css.iconHeart} />
              </div>
              {props.watchesCount}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardFleaMarket
