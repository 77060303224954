import 'core-js/actual' // polyfill all actual features - stable ES, web standards and stage 3 ES proposals
import 'regenerator-runtime/runtime'
import '@stackflow/plugin-basic-ui/index.css' // stackflow
import './_app/styles/globalSeedDesign' // seed-design
import './_app/styles/global.scss'
import '@daangn/karrot-clothes/lib/index.css'
import '@daangn/search-sdk/lib/index.css'
import '@daangn/pull-to-refresh/lib/index.css'
import '@daangn/karrotflyer/index.css'

import { StrictMode } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { install } from 'resize-observer'
import App from './App'
import { STAGE, USE_MSW } from './_app/constants/common'
import { initSentry } from './_app/utils/initSentry'
import { initDatadogRum } from './_app/utils/initDatadogRum'
import { initMSW } from './_app/utils/initMSW'

if (!window.ResizeObserver) {
  install()
}

if (STAGE === 'development' && USE_MSW === 'true') {
  initMSW()
}

if (STAGE === 'production') {
  initDatadogRum()
}

if (STAGE === 'production' || STAGE === 'alpha') {
  initSentry()
}

const container = document.getElementById('root') as HTMLElement

if (container.hasChildNodes()) {
  hydrateRoot(container, <App />)
} else {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
// todo
