import type { ReactNode } from 'react'
import { forwardRef, memo, useMemo } from 'react'
import { TAG_SIZE } from './constants'
import * as css from './TagEllipsis.css'
import type { RecipeVariants } from '@vanilla-extract/recipes'

export type TagEllipsisProps = {
  children: ReactNode
  [TAG_SIZE.SMALL]?: boolean
  [TAG_SIZE.MEDIUM]?: boolean
  [TAG_SIZE.LARGE]?: boolean
  bold?: boolean
  icon?: ReactNode
  color?: NonNullable<RecipeVariants<typeof css.tag>>['color']
}

const TagEllipsis = forwardRef<HTMLDivElement, TagEllipsisProps>(
  (
    {
      small = false,
      medium = true,
      large = false,
      bold = false,
      color,
      ...props
    },
    ref
  ) => {
    const size = useMemo(() => {
      if (small) {
        return TAG_SIZE.SMALL
      } else if (large) {
        return TAG_SIZE.LARGE
      } else {
        return TAG_SIZE.MEDIUM
      }
    }, [small, medium, large])
    return (
      <>
        {props.icon}
        <div
          ref={ref}
          className={css.tag({
            size,
            bold,
            color,
          })}
        >
          {props.children}
        </div>
      </>
    )
  }
)

TagEllipsis.displayName = 'TagEllipsis'

export default memo(TagEllipsis)
