import { Placeholder } from '../skeleton'
import { Body, Container, Header, Title } from './HotKeywords.css'
export interface HotKeywordsPlaceholderProps {
  className?: string
}
const HotKeywordsPlaceholder = (props: HotKeywordsPlaceholderProps) => {
  return (
    <article className={`${Container} ${props.className ?? ''}`}>
      <header className={Header}>
        <h2 className={Title} a11y-label="인기 검색">
          인기 검색
        </h2>
      </header>
      <ol className={Body} style={{ display: 'flex' }}>
        <Placeholder
          width="3.5rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            left: '1rem',

            right: '0.5rem',
          }}
        />
        <Placeholder
          width="3.2rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
        <Placeholder
          width="3.5rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
        <Placeholder
          width="3.7rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
      </ol>
    </article>
  )
}

export default HotKeywordsPlaceholder
