export * from './Image'

export const getResultFor = <T extends unknown>(
  response?: { result: T } | null
) => {
  if (!response) {
    return null
  }

  return response.result
}

/**
 * v2 API 통일 규격
 */
export interface SearchResponseType<T extends unknown> {
  /**
   * API 상태 코드
   */
  code: number

  /**
   * 응답값은 result 하위로 들어오게 구조 정립
   */
  result: T
}
