/**
 * 스테이지
 */
export const STAGE = process.env.REACT_APP_STAGE as
  | 'production' // 프로덕션 앱
  | 'alpha' // 알파 앱
  | 'staging' // 검색팀 스테이징 웹 페이지
  | 'development' // 로컬 개발 환경

/**
 * MSW 사용 여부
 */
export const USE_MSW = process.env.USE_MSW as string

/**
 * 커밋 Hash
 */
export const VERSION = process.env.VERSION as string

/**
 * Sentry DSN
 */
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string

/**
 * DataDog Application Id
 */
export const DATADOG_APPLICATION_ID = process.env
  .REACT_APP_DATADOG_APPLICATION_ID as string

/**
 * DataDog Client Token
 */
export const DATADOG_CLIENT_TOKEN = process.env
  .REACT_APP_DATADOG_CLIENT_TOKEN as string

/**
 * https://clarity.microsoft.com/projects/view/g4rphs6toy/dashboard
 */
export const CLARITY_PROJECT_ID = 'g4rphs6toy'

/**
 * KarrotMarket 서비스(중고거래 서비스) 엔드포인트
 */
export const KARROT_MARKET_ENDPOINT =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://webapp.kr.karrotmarket.com'
    : 'https://webapp.alpha.kr.karrotmarket.com'

/**
 * @deprecated
 * 검색 서비스 엔드포인트(레거시)
 */
export const SEARCH_ENDPOINT_LEGACY = (() => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return 'https://search-server.alpha.kr.karrotmarket.com'
  }

  if (STAGE === 'staging') {
    return 'https://search-server-stage.kr.karrotmarket.com'
  }

  return 'https://search-server.kr.karrotmarket.com'
})()
/**
 * 검색 서비스 엔드포인트(v4:grpc-gateway)
 */
export const SEARCH_ENDPOINT = (() => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return 'https://search-server-gw.alpha.kr.karrotmarket.com'
  }

  if (STAGE === 'staging') {
    return 'https://search-server-stage-gw.alpha.kr.karrotmarket.com'
  }

  return 'https://search-server-gw.kr.karrotmarket.com'
})()

/**
 * 검색 웹뷰 BFF 엔드포인트
 */
export const SEARCH_FRONT_SERVER_ENDPOINT = (() => {
  if (STAGE === 'development') {
    return 'http://localhost:8080'
  }

  if (STAGE === 'alpha') {
    return 'https://search-front.alpha.kr.karrotmarket.com'
  }

  return 'https://search-front.kr.karrotmarket.com'
})()

/**
 * 프로퍼티 서비스 엔드포인트
 */
export const X_PROPERTY_ENDPOINT =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://xproperty.kr.karrotmarket.com'
    : 'https://xproperty.alpha.kr.karrotmarket.com'

/**
 * 검색 광고 collect 엔드포인트
 */
export const AD_COLLECT_ENDPOINT = (() => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return 'https://ads-dmp-collect.alpha.kr.karrotmarket.com'
  }

  return 'https://ads-dmp-collect.kr.karrotmarket.com'
})()

/**
 * 비즈 프로필 웹뷰 앱
 */
export const BIZ_PLATFORM_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://bizprofile.karrotwebview.com'
    : 'https://bizprofile.alpha.karrotwebview.com'

/**
 * 동네 알바 웹뷰 앱
 */
export const JOBS_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://jobs.karrotwebview.com'
    : 'https://jobs.alpha.karrotwebview.com'

/**
 * POI 웹뷰 앱
 */
export const POI_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://place.karrotwebview.com'
    : 'https://place.alpha.karrotwebview.com'

/**
 * 동네 모임 웹뷰 앱
 */
export const KARROT_GROUP_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://community-web-group.karrotwebview.com'
    : 'https://community-web-group.alpha.karrotwebview.com'

/**
 * 업체 상품 웹뷰 앱
 *
 * 더보기: /search,
 * 게시글: /products
 */
export const COMMERCE_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://commerce.karrotwebview.com'
    : 'https://commerce.alpha.karrotwebview.com'

/**
 * 중고차 직거래 웹뷰 앱
 *
 * 더보기(홈): /
 * 더보기(검색결과): /search
 * 게시글: /articles
 *
 */
export const CAR_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://car.karrotwebview.com'
    : 'https://car.alpha.karrotwebview.com'

export const COMMUNITY_AGORA_APP =
  STAGE === 'production' || STAGE === 'staging'
    ? 'https://community-web-agora.kr.karrotmarket.com'
    : 'https://community-web-agora.alpha.kr.karrotmarket.com'

/**
 * 검색 로컬 스토리지에 PersistedState JSON을 저장할 키
 */
export const SEARCH_LOCAL_STORAGE_STATE_KEY = `search/${STAGE}/v1/state`

/**
 * 기본 검색 결과 썸네일
 */
export const THUMBNAIL_FALLBACK =
  'https://assetstorage.krrt.io/1149181061857716525/4df689c7-797d-4037-b5f5-9a3aa2be5c46/width=560,height=560.png'

/**
 * 로컬 스토리지에 (Code Split) Chunk File 로드 실패 시 Flag를 저장할 키
 */
export const LOCAL_STORAGE_REFRESH_CHUNK_LOAD_FAILED_FLAG_KEY = `search/${STAGE}/v1/refresh_chunk_load_failed_flag`

/**
 * 검색 로컬 스토리지에 experiment JSON을 저장할 키
 */
export const SEARCH_LOCAL_STORAGE_EXPERIMENT_KEY = `search/${STAGE}/v1/experiment`

/**
 * (Staging Only) 검색 로컬 스토리지에 foundation override JSON을 저장할 키
 */
export const SEARCH_LOCAL_STORAGE_FOUNDATION_OVERRIDE_KEY = `search/${STAGE}/v1/foundationOverride`

/**
 * (Search Admin Preview Only) 검색 쿠키에서 foundation override JSON을 저장할 키
 */
export const SEARCH_COOKIE_STORAGE_FOUNDATION_OVERRIDE_KEY = (() => {
  const stage = STAGE === 'production' ? 'production' : 'alpha'

  return `search-${stage}-foundationOverride`
})()

/**
 * 네트워크 재시도 숫자
 */
export const RETRY_COUNT = 3

/**
 * 네트워크 타임아웃
 */
export const NETWORK_TIMEOUT = 20000

/**
 * 검색 로컬 스토리지에 최근 검색어 마이그레이션 상태를 저장하는 키
 * NOTE: 먼 훗날 서버 최근 검색어가 안정화되고, 서버로 완전히 마이그레이션을 하게 되면 이 키는 삭제될 예정
 */
export const SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS_KEY = `search/${STAGE}/v1/recentSearchMigrationStatus`

/**
 * 검색 로컬 스토리지에 저장된 최근 검색 마이그레이션 상태 값
 */
export const SEARCH_LOCAL_STORAGE_RECENT_SEARCH_MIGRATION_STATUS = {
  // 로컬스토리지에 쿼리만 있는 상태
  IN_MIGRATION: 'IN_MIGRATION',
  // 로컬스토리지에 시간정보가 추가된 상태
  DONE: 'DONE',
  // 서버와 로컬스토리지가 혼합된 상태
  WITH_SERVER_RESPONSE: 'WITH_SERVER_RESPONSE',
} as const
