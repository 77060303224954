import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'
import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { V4SearchRequest } from '../../__codegen__/__openapi__/search-server/place/v4/place'
import {
  Configuration,
  PlaceServiceApiFactory,
} from '../../__codegen__/__openapi__/search-server/place/v4/place'

import type { SearchReferrerType } from '../../referrer/types'
import {
  findFunnelFromValueById,
  findQueryFromValueById,
} from '../../referrer/utils'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import type { collectionWithError } from '../../error/utils/getPreDefinedServiceError'
import { getPreDefinedServiceError } from '../../error/utils/getPreDefinedServiceError'
import { convertCollectionMetaToConnection } from '../../_app/utils/convertCollectionToConnection'

interface PlacesV4SearchRequestParamsType extends V4SearchRequest {
  referrer: SearchReferrerType
}
let serviceCache: ServiceSearchPlaceType | null = null
export type ServiceSearchPlaceType = ReturnType<typeof ServiceSearchPlace>

export function getServiceSearchPlace({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceSearchPlace({
    baseUrl: SEARCH_ENDPOINT,
    user,
    app,
  }))
}

const ServiceSearchPlace = ({
  baseUrl,
  user,
  app,
}: {
  baseUrl: string
  user: UserType
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    headers: headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
    ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
  })

  const client = PlaceServiceApiFactory(
    new Configuration({
      apiKey: user.authToken,
    }),
    baseUrl,
    axiosInstance
  )

  return {
    /**
     * 장소(poi/bizaccount) 목록 조회
     */
    getPlaces: async ({
      query,
      regionId,
      pageSize,
      pageToken,
      sort,
      useLocation,
      location,
      useMap,
      mapLocation,
      referrer,
    }: PlacesV4SearchRequestParamsType) => {
      const [error, resp] = await to(
        client.placeServiceSearch(
          {
            regionId: regionId,
            pageSize: pageSize,
            query: query,
            pageToken: pageToken,
            sort: sort,
            useLocation: useLocation,
            location: location,
            useMap: useMap,
            mapLocation: mapLocation,
          },
          undefined, //xSearchUserId: Deprecated
          referrer.queryId, //xSearchQueryId
          undefined, //xSearchRequestId: Deprecated
          undefined, //xRequestId
          undefined, //xSearchSessionExpiredTime: Deprecated
          undefined, //xSearchSessionId: Deprecated
          undefined, //xKarrotSessionId
          referrer.experiment.headerSegmentValue ?? '', //xSearchTestGroup
          undefined, //xSearchPreviousQuery: Deprecated
          referrer.searchFunnelId, //xSearchFunnelId
          findFunnelFromValueById(referrer.funnelFromId), //xSearchFunnelFrom
          findQueryFromValueById(referrer.queryFromId), //xSearchQueryFrom
          referrer.tab.toLowerCase(), //xSearchTab
          referrer.screenDepthName.toLowerCase(), //xSearchScreenDepthName
          undefined, //xSearchReleaseContents: Deprecated
          undefined, //xSearchOrigin
          undefined, //xUserAgent
          undefined //xForwardedFor: Deprecated
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!resp?.data) {
        return null
      }

      const preDefinedServiceError = getPreDefinedServiceError(
        resp.data as collectionWithError
      )

      const connection = convertCollectionMetaToConnection(resp.data.documents)
      return {
        connection,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          resp.headers
        ),
        ...(preDefinedServiceError && { error: preDefinedServiceError }),
      }
    },
  }
}
