export const initMSW = () => {
  import('../../_msw').then(
    (module) => {
      module.worker.start()
    },
    (error) => {
      console.log(`[DEV ONLY] Failed to start MSW: `, error)
    }
  )
}
