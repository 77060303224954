import * as Sentry from '@sentry/react'
import type { AxiosError } from 'axios'
import axios from 'axios'
import type { SearchResponseType } from '../../services/common'
import { isMaintenanceError } from '../../error/utils/getMaintenanceError'
import { isClientTimeoutError } from '../../error/utils/isClientTimeoutError'
import NetworkTimeoutError from '../../error/class/NetworkTimeoutError'

export function captureException(error?: Error | null, errorInfo?: any) {
  if (error?.message === 'Network Error') {
    return
  }
  if (error?.message === 'Network request failed') {
    return
  }

  // 클라이언트 타임아웃 에러인 경우, 타임아웃 시간 최적화를 위해 수집
  if (error && axios.isAxiosError(error) && isClientTimeoutError(error)) {
    Sentry.captureException(new NetworkTimeoutError(`${error.config?.url}`))
    return
  }

  // NOTE: 정기점검 에러로 인한 로그는 수집하지 않아요.
  if (error && isMaintenanceError(error)) {
    return
  }

  if (error) {
    Sentry.captureException(error, { extra: errorInfo })
  }
}

export function captureAxiosError<T extends SearchResponseType<any>>(
  error: any,
  options?: {
    excludeErrors: number[]
  }
): {
  status: number
  data: T
} | null {
  // 네트워크 에러면 sentry에 안보냄
  if (
    error?.message === 'Network Error' ||
    error?.message === 'Network request failed'
  ) {
    return null
  }

  // 클라이언트 타임아웃 에러인 경우, 타임아웃 시간 최적화를 위해 수집
  if (error && axios.isAxiosError(error) && isClientTimeoutError(error)) {
    Sentry.captureException(new NetworkTimeoutError(`${error.config?.url}`))
    return null
  }

  if (error && axios.isAxiosError(error)) {
    const serverError = error as AxiosError<T>

    if (serverError && serverError.response) {
      const sendError = !options?.excludeErrors.includes(
        serverError.response?.status
      )

      if (sendError) {
        Sentry.captureException(serverError)
      }

      return {
        status: serverError.response.status,
        data: serverError.response.data,
      }
    }
  }

  // 파악할 수 없는 에러면 보냄
  if (error) {
    Sentry.captureException(error)
  }

  return null
}
