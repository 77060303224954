import { makeId } from './core/makeId';
import { getCurrentEnvironment } from './core/getCurrentEnvironment';
export const installSearchBridgeDriver = () => {
    const listeners = [];
    const postMessage = (message) => {
        var _a, _b;
        switch (getCurrentEnvironment()) {
            case 'Android':
                (_a = window.AndroidFunction) === null || _a === void 0 ? void 0 : _a.searchHandler(JSON.stringify(message));
                break;
            case 'Cupertino':
                (_b = window.webkit) === null || _b === void 0 ? void 0 : _b.messageHandlers.searchHandler.postMessage(JSON.stringify(message));
                break;
            case 'Web':
                console.warn('SearchBridge의 통신 드라이버는 일반 데스크탑, 모바일 브라우저 환경을 지원하지 않아요.' +
                    ' 현재 환경이 당근마켓 WebView 환경인지 확인해주세요.');
                break;
        }
    };
    const addMessageListener = (listener) => {
        listeners.push(listener);
        return function dispose() {
            const i = listeners.findIndex((l) => l === listener);
            listeners.splice(i, 1);
        };
    };
    const onSearchMessage = (message) => {
        if (!message) {
            return console.error('[SearchBridge] Native에서 메시지가 빈 채로 도착했어요.');
        }
        listeners.map((l) => l(message));
    };
    const onQueried = (queryName, requestBody) => {
        const id = makeId();
        const message = {
            id,
            type: queryName,
            payload: requestBody,
        };
        postMessage(message);
        return new Promise((resolve, reject) => {
            const dispose = addMessageListener((arrivedMessage) => {
                if (arrivedMessage.id === message.id) {
                    dispose();
                    if ('error' in arrivedMessage) {
                        reject(new Error(arrivedMessage.error));
                    }
                    else {
                        resolve(arrivedMessage.payload);
                    }
                }
            });
        });
    };
    const onSubscribed = (subscriptionName, requestBody, listener) => {
        const id = makeId();
        const message = {
            id,
            type: subscriptionName,
            payload: requestBody,
        };
        postMessage(message);
        return addMessageListener((arrivedMessage) => {
            if (arrivedMessage.id === message.id) {
                if ('error' in arrivedMessage) {
                    listener(new Error(arrivedMessage.error), null);
                }
                else {
                    listener(null, arrivedMessage.payload);
                }
            }
        });
    };
    const driver = {
        getCurrentEnvironment,
        postMessage,
        addMessageListener,
        onSearchMessage,
        onQueried,
        onSubscribed,
    };
    if (typeof window !== 'undefined') {
        if (window.searchNative) {
            throw new Error('SearchBridge의 통신 드라이버가 이미 설치되어 있어요.' +
                ' 기존에 설치된 드라이버를 덮어쓰므로 비정상적인 작동이 있을 수 있어요.' +
                ' installSearchBridgeDriver가 여러번 호출되고 있는지 확인해주세요.' +
                ' (<React.StrictMode />의 영향일 수 있어요.)');
        }
        window.searchNative = driver;
        if (window.native) {
            window.native.onSearchMessage = driver.onSearchMessage;
        }
    }
    const uninstall = () => {
        if (typeof window !== 'undefined') {
            delete window.searchNative;
        }
    };
    return {
        driver,
        uninstall,
    };
};
